/* You can add global styles to this file, and also import other style files */

@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import 'assets/theme/colors.scss';

@import '~@ng-select/ng-select/themes/material.theme.css';

@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/_ag-theme-alpine-mixin.scss';
@import '~@ag-grid-community/core/dist/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin.scss';
@import '~@ag-grid-community/core/dist/styles/ag-grid.scss';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

.ag-theme-alpine {
    @include ag-theme-alpine(
        (
            borders-secondary: false,
            border-radius: 0px,
            checkbox-unchecked-color: var(--checkbox-border-color),
        )
    );
}

.ag-theme-alpine-dark {
    @include ag-theme-alpine-dark(
        (
            borders-secondary: false,
            border-radius: 0px,
            checkbox-unchecked-color: var(--checkbox-border-color),
        )
    );
}

.ag-theme-alpine .ag-select .ag-picker-field-wrapper {
    border-radius: 0px !important;
    min-height: 100% !important;
    height: 100% !important;
}
.ag-cell {
    line-height: min(var(--ag-line-height, 40px), 40px) !important;
}

.ag-theme-alpine-dark .ag-select .ag-picker-field-wrapper {
    border-radius: 0px !important;
    min-height: 100% !important;
    height: 100% !important;
}

.ag-root-wrapper-body .ag-layout-auto-height {
    min-height: 200px;
}

circle {
    stroke: $theme-primary-color !important;
}

.mat-dialog-container {
    background-color: var(--primary-dark-secondary-bg-color) !important;
    color: var(--primary-text-color);
    padding: 0 !important;
    overflow: hidden !important;
}
.dialog-backdrop {
    background-color: rgba(0, 0, 0, 0.7);
}
.app-detail-model .mat-dialog-container {
    background-color: var(--primary-dark-secondary-bg-color) !important;
    color: var(--primary-text-color) !important;
    padding: 0 !important;
}

.json-container .ngx-json-viewer {
    overflow: unset !important;
}

.decision-actions button {
    width: 90% !important;
}

.search-apps-container .search-container {
    padding: 0 !important;
}

.decision-accordion .mat-expansion-panel {
    box-shadow: none !important;
}

.decision-accordion .mat-expansion-panel-header {
    padding: 0 16px;
}

.header-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0.8em;
}

.header-title .flight-info-container .flight-info-row .flight-info h4 {
    font-size: 1.57em !important;
    font-weight: 500;
}

.header-title .flight-info-container .flight-info-row .flight-info h5 {
    font-size: 0.88 !important;
    font-weight: 300 !important;
    margin-top: 1em !important;
}

.header-title
    .flight-info-container
    .flight-info-row
    .flight-info
    h5
    .from-date {
    display: inline !important;
}

.flight-applications-title
    .flight-info-container
    .flight-info-row
    .flight-info
    h5 {
    margin-top: 0.3em !important;
}

.header-title .flight-info-container {
    align-items: flex-start !important;
    margin-top: 0.4em;
}

.header-title .flight-info-container .total-count {
    margin-top: 0em !important;
}

.flight-applications .alternative-icon {
    height: 2em !important;
    width: 2em !important;
}

.flight-applications .flight-info-container {
    align-items: flex-start !important;
}

.decision-accordion .mat-expansion-panel-body {
    padding: 0 16px;
}

.payment-info .mat-expansion-panel-body {
    padding: 24px;
}

.app-details-overlay {
    background: rgb(0 0 0 / 90%);
}

.lang-radio-button .mat-radio-label .mat-radio-container {
    margin-top: 5px;
    align-self: self-start;
}

.mat-datepicker-toggle {
    color: $theme-primary-color !important;
}

.tab-pill .mat-chip-list .mat-chip-list-wrapper .mat-chip {
    cursor: pointer;
}

.trip-info-date .mat-form-field-wrapper .mat-form-field-flex {
    padding: 0.75em 0 0 0 !important;
}

.date-picker-view .mat-form-field-wrapper .mat-form-field-flex {
    padding: 0.75em 0 0 0 !important;
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: $theme-primary-color !important;
}
.mat-datepicker-content {
    overflow: hidden !important;
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $theme-primary-color !important;
}

#snav .mat-list-item.selected > .mat-list-item-content > a {
    background: $theme-primary-color !important;
}

.ng-select .ng-select-container:after {
    border-bottom: thin solid $theme-primary-color !important;
}

.gender-select .ng-select-container:after {
    border-bottom: 0.1em solid $theme-primary-color !important;
}
.ag-invisible {
    display: none !important;
}

.pill-info .mat-chip-list-wrapper {
    width: max-content !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    color: $theme-primary-color !important;
}

.ng-clear-wrapper {
    display: none !important;
}

.count-pill .mat-chip-list .mat-chip-list-wrapper .pill {
    padding: 0.7em 1em !important;
}

.reject-checkbox {
    .mat-checkbox-background {
        background-color: $denied-primary-color !important;
    }
}

.question-checkbox {
    .mat-checkbox-background {
        background-color: $question-primary-color !important;
    }
}

.outbound-question-checkbox {
    .mat-checkbox-background {
        background-color: $question-primary-color !important;
    }
}

.examine-checkbox {
    .mat-checkbox-background {
        background-color: $examine-primary-color !important;
    }
}

.outbound-examine-checkbox {
    .mat-checkbox-background {
        background-color: $examine-primary-color !important;
    }
}

.quarantine-checkbox {
    .mat-checkbox-background {
        background-color: $quarantine-primary-color !important;
    }
}

.reverse-checkbox,
.send-to-pending-checkbox {
    .mat-checkbox-background {
        background-color: $inverted-bg-color !important;
    }
}
.clear-flag-checkbox {
    .mat-checkbox-background {
        background-color: $theme-secondary-color !important;
    }
}

.agent-exit-checkbox {
    .mat-checkbox-background {
        background-color: $agent-exit-bg-color !important;
    }
}

.agent-entry-checkbox {
    .mat-checkbox-background {
        background-color: $agent-entry-bg-color !important;
    }
}

.approve-checkbox {
    .mat-checkbox-background {
        background-color: $approved-primary-color !important;
    }
}

.note-checkbox,
.in-review-checkbox,
.incomplete-checkbox,
.accept-incomplete-checkbox {
    .mat-checkbox-background {
        background-color: $secondary-bg-color !important;
    }
    .mat-checkbox-checkmark-path {
        stroke: $primary-text-color !important;
    }
}

.trav-green-checkbox {
    .mat-checkbox-background {
        background-color: $theme-primary-color !important;
    }
}

.checkbox {
    .mat-checkbox-label {
        color: var(--primary-text-color) !important;
    }
}

.trav-teal-bg {
    background-color: $header-bg-color !important;
    color: $inverted-text-color !important;
}

.trav-green-bg {
    background-color: $theme-primary-color !important;
    color: $inverted-text-color !important;
}

.trav-green {
    color: $theme-primary-color !important;
}

.trav-grey-bg {
    background-color: $trav-grey-bg-color !important;
    color: $trav-grey-color !important;
    box-shadow: none !important;
}

.trav-red-bg {
    background-color: $red-color !important;
    span {
        color: $white-color !important;
    }
}

#pdfPreview .ng2-pdf-viewer-container {
    overflow: hidden !important;
}

.mat-focused .mat-form-field-label {
    /*change color of label*/
    color: $theme-primary-color !important;
}

.disabled-btn {
    opacity: 0.6;
    color: var(--primary-text-color);
    background-color: $theme-primary-color !important;
    cursor: context-menu !important;
}

.ng-select .ng-select-container {
    color: inherit;
}

.ng-select,
.ng-select div,
.ng-select input,
.ng-select span {
    line-height: 1.5;
    font-weight: 400;
    font-size: 1rem;
    color: inherit;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    width: calc(100% - 15px);
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden;
}

.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-opened .ng-placeholder {
    width: calc(100% + 15px) !important;
}
.rejectionInfo label {
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.rejectionInfo label .mat-checkbox-inner-container {
    margin: 2px 8px;
}

.rejectionInfo label .mat-checkbox-label {
    width: 90%;
}

.rejectionInfo label .mat-checkbox-label div {
    white-space: pre-wrap;
}

.mat-form-field-underline {
    /*change color of underline*/
    background-color: $theme-primary-color !important;
}
.countries-main-container {
    min-height: 200px;
}
.read-only .mat-form-field-underline {
    /*change color of underline*/
    background-color: $muted-text-color !important;
}
.read-only
    .mat-form-field-infix
    .mat-form-field-label-wrapper
    .mat-form-field-label {
    /*change color of underline*/
    color: $muted-text-color !important;
}

.read-only .ng-select-container .ng-value-container .ng-placeholder {
    color: $muted-text-color !important;
}

.mat-form-field-ripple {
    /*change color of underline when focused*/
    background-color: $theme-primary-color !important;
}

.form-content-container {
    display: flex;
    flex-direction: column;
}

.select-countries .select2-container {
    width: 100% !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background: $theme-primary-color !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $theme-primary-color !important;
}
.mat-radio-outer-circle {
    border-color: var(--radio-button-border) !important;
}
.mat-radio-label {
    color: var(--primary-text-color) !important;
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
    color: var(--primary-text-color) !important;
    opacity: 0.6;
}

.select-countries .select2-container .selection .select2-selection {
    border: none;
    border-bottom: 1px solid $theme-primary-color;
    border-radius: 0px;
}

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-sidenav-container,
.mat-sidenav-content,
.mat-tab-body-content {
    transform: none !important;
}

.mat-dialog-actions {
    margin-bottom: 0px !important;
}

.notification-type-select .ng-select-container .ng-value-container {
    margin-top: 0.4em;
}

.notification-type-select {
    padding-bottom: 0 !important;
    margin-left: 8px;
}

.upload-zone {
    padding-bottom: 0 !important;
    margin-left: 8px;
}

// .search-field {
//   padding-bottom: 0 !important;
// }

.form-tabs .mat-tab-labels {
    justify-content: unset !important;
}
.right-container
    .mat-tab-group
    .mat-tab-header
    .mat-tab-label-container
    .mat-tab-list
    .mat-tab-labels
    .mat-tab-label {
    width: 50% !important;
}

.mat-tab-header {
    border-bottom: unset !important;
}

.chip-container .mat-standard-chip {
    border-radius: 5px !important;
}

.ngx-gallery-image-text {
    width: fit-content !important;
    border-radius: 7px !important;
    right: 10px !important;
    bottom: 10px !important;
}

.ngx-gallery-preview-text {
    position: absolute;
    width: fit-content !important;
    border-radius: 7px !important;
    right: 10px !important;
    bottom: 10px !important;
}

.pure-css-loader {
    height: 7px;
    margin: 35% 7%;
    border-radius: 10px;
    background: $light-grey;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

.cdk-overlay-pane {
    -webkit-user-select: none;
    overflow-y: auto;
    z-index: unset !important;
}

.overflow-visible {
    .mat-dialog-container {
        overflow: visible !important;
    }
}

.overflow-auto {
    overflow: visible !important;
    .mat-dialog-container {
        overflow: auto !important;
    }
}

.mat-tooltip {
    margin: 0px !important;
}

.mat-menu-content:not(:empty) {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.mat-menu-panel {
    background-color: var(--primary-dark-secondary-bg-color) !important;
    width: fit-content !important;
    box-shadow: none !important;
    height: 100% !important;
    overflow: hidden !important;
    min-height: 40px !important;
}

.flight-date .mat-form-field-wrapper .mat-form-field-flex {
    padding: 0.37em 0em 0em 0em !important;
}

.flight-date
    .mat-form-field-wrapper
    .mat-form-field-flex
    .mat-form-field-infix {
    padding: 0.4375em 0 !important;
}

.flight-date
    .mat-form-field-wrapper
    .mat-form-field-flex
    .mat-form-field-infix
    .mat-form-field-label-wrapper
    .mat-form-field-label {
    top: 1.8em !important;
}

.flight-date.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label,
.flight-date.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.3em) scale(0.75) !important;
}

.clickable:hover {
    opacity: 0.7;
    cursor: pointer;
}

.draggable {
    cursor: move;
}

.gm-style-iw {
    max-height: fit-content !important;
}
.gm-style-iw-d {
    max-height: fit-content !important;
    overflow: hidden !important;
}

.mat-tab-label {
    opacity: 1 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    display: flex !important;
    align-items: center !important;
}

.tool-panel-search .search-container {
    padding: 0 !important;
    margin: 0;
}
.tool-panel-search .search-container .search-bar {
    height: 30px;
    max-width: 10.5em;
    font-size: 1em;
}

.tool-panel-search .search-container .search-icon {
    padding-right: 10px !important;
}

.tool-panel-search .search-container .search-icon .mat-icon {
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.validation-spinner svg {
    height: 1.5em !important;
    width: 1.5em !important;
}

.validate-attachment-spinner svg {
    height: 1.2em !important;
    width: 1.2em !important;
}
.tooltip-txt {
    text-align: center !important;
    display: flex;
    justify-self: center;
    justify-content: center;
    align-items: center;
}

.residence-country ng-select {
    margin-top: -16.5px;
}

.image-preview-container .ng2-pdf-viewer-container {
    overflow: hidden !important ;
}

.pcr-loading-spinner svg circle {
    stroke: $white-color !important;
}
.pdf-container .pdfViewer.removePageBorders .page {
    margin: 0px !important;
}

.agreement .mat-checkbox-inner-container {
    margin-top: 5px !important;
}

.checkbox-section .mat-checkbox-inner-container {
    margin-top: 5px !important;
}

.residence-country
    .select-container
    .ng-select
    .ng-select-container
    .ng-value-container
    .ng-placeholder {
    margin-top: 0px !important;
}

.auto-complete-input .search-establishments .controls {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid transparent;
    background-color: $white-color;
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding: 0 11px 0 13px;
    font-family: Roboto, sans-serif;
    position: absolute;
    border-radius: 2px;
    margin-top: 0.69em;
    margin-left: 0.69em;
    font-weight: 300;
    font-size: 17px;
    color: $black-color;
    outline: none;
    height: 33px;
    z-index: 4;
    width: 56%;
}
.controls:focus {
    border-color: $blue-border-color;
}

.google-place
    .mat-form-field-wrapper
    .mat-form-field-flex
    .mat-form-field-infix
    .mat-form-field-label-wrapper
    .mat-form-field-label {
    margin-top: -13px;
    height: 15px;
    font-size: 12px;
}

.flight-number-input .mat-form-field-underline {
    display: none !important;
}
.flight-number-input
    app-ng-select
    ng-select
    .ng-select-container
    .ng-value-container {
    border-top: 0em !important;
    padding: 0.1em 0em !important;
}

.flight-number-input app-ng-select ng-select .ng-select-container {
    min-height: unset !important;
}

.flight-number-input .mat-form-field-label-wrapper .mat-form-field-label {
    margin-top: 7px !important;
}

.side-bar .ag-root-wrapper .ag-root-wrapper-body {
    padding-right: 3em;
}

#minifiedStatus .footer mat-chip-list .mat-chip-list-wrapper {
    margin: 0 !important;
}

.flight-card app-pill mat-chip {
    cursor: pointer;
}

#flightNumberDiv .label-container .flight-number-layout .title-container {
    max-width: 110px;
}

#flightNumberDiv
    .label-container
    .flight-number-layout
    .title-container
    .flight-number-padding {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.search-result-card mat-card mat-card-content {
    background-color: $faded-light-color !important;
}

.mat-focused .mat-form-field-required-marker {
    color: $theme-primary-color !important;
}

.pdf-container pdf-viewer .ng2-pdf-viewer-container {
    overflow: hidden !important;
}

.full-screen-pdf .ng2-pdf-viewer-container {
    max-height: 85vh !important;
}

.full-modal .mat-dialog-container .dialog-box-container {
    width: 100% !important;
}

.switch-apps-paginator .mat-paginator-outer-container .mat-paginator-container {
    min-height: unset !important;
    height: fit-content !important;
    padding: 0 !important;
}

.switch-apps-paginator
    .mat-paginator-outer-container
    .mat-paginator-container
    .mat-paginator-range-actions
    .mat-paginator-range-label {
    font-weight: 600 !important;
    min-width: 5em;
}

.invalid {
    .mat-form-field-underline::before {
        background-color: $error-text-color !important;
    }
    .mat-form-field-underline {
        background-color: $error-text-color !important;
        .mat-form-field-ripple {
            background-color: $error-text-color !important;
        }
    }
    .mat-datepicker-toggle {
        color: $error-text-color !important;
    }

    .ng-select .ng-select-container:after {
        border-bottom: thin solid $error-text-color !important;
    }
}

.updated-row {
    color: $updated-row-text-color !important;
    font-weight: bolder !important;
}
.disabled-row {
    color: var(--disabled-icon-color) !important ;
    font-weight: bolder !important;
    .icon-container {
        opacity: 0.4 !important;
    }
    .title-text {
        color: var(--disabled-icon-color) !important ;
    }
    .mat-icon {
        opacity: 0.5 !important;
    }
}
.updated-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    padding: 0.4em;
    background: $updated-badge-bg-color;
    color: $black-color;
    font-weight: 500;
    border-radius: 3px;
    font-size: 0.6em;

    line-height: 1;
}

.danger-txt {
    color: $quarantine-primary-color !important;
}

app-search-row {
    app-text-input,
    app-date-picker,
    app-date-time-picker,
    app-interval,
    app-ng-select:not(.input-selector) {
        width: 100%;
    }
}

ng-select {
    .ng-select-container {
        .ng-value {
            app-flight-info-option {
                app-flight-identity {
                    .title-text {
                        .title-text {
                            color: var(--primary-text-color) !important;
                        }
                    }
                }
            }
        }
    }
}

app-form {
    .page {
        background-color: var(--secondary-dark-primary-bg-color) !important;
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        width: 0%;
    }
    100% {
        width: 80%;
    }
}

@keyframes spin {
    0% {
        width: 0%;
    }
    100% {
        width: 80%;
    }
}

@media screen and (max-width: 768px) {
    .select2-container--open {
        top: unset !important;
    }
    .select2-container--open .select2-dropdown--below {
        bottom: 6px !important;
    }
}

@media screen and (max-width: 490px) {
    .auto-complete-input .search-establishments .controls {
        width: 60% !important;
    }

    .preview-cell
        .pdf-container
        pdf-viewer
        .ng2-pdf-viewer-container
        .pdfViewer {
        height: 100% !important;

        .page {
            height: 100% !important;

            .canvasWrapper {
                height: 100% !important;

                canvas {
                    height: 100% !important;
                }
            }
        }
    }
}

@media screen and (min-width: 375px) {
    .full-screen-pdf .ng2-pdf-viewer-container {
        max-height: 75vh !important;
        .ng2-pdf-viewer-container {
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }
}

@media screen and (min-width: 411px) {
    .full-screen-pdf .ng2-pdf-viewer-container {
        max-height: 85vh !important;
        .ng2-pdf-viewer-container {
            overflow-x: auto;
        }
    }
}
.ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
    height: 100% !important;
}

.ng-select.ng-select-multiple
    .ng-select-container.ng-has-value
    .ng-value-container {
    padding-top: 1.1875em !important;
}

.ng-select.ng-select-focused
    .ng-select-container
    .ng-value-container
    .ng-placeholder {
    color: $theme-primary-color !important;
}

.mat-form-field-flex {
    background-color: transparent !important;
}

.purpose-of-visit-select .ng-value-container .ng-placeholder {
    margin-top: 4px;
}
.gender-select .ng-value-container .ng-placeholder {
    margin-top: 4px;
}
.ng-select .ng-select-container .ng-value-container .ng-input > input {
    color: var(--primary-text-color) !important;
}

.margin-top {
    position: absolute !important;
    top: 0;
    left: 0;
    margin-top: 62px !important;
    min-height: 100% !important;
}

.mat-chip.mat-standard-chip::after {
    background: unset !important;
}

.alert-container {
    border: 1px solid transparent;
    border-radius: 0.25rem;
    position: relative;
    font-size: 14px;
    padding: 5px;
    white-space: normal;

    .alert-icon {
        font-size: 1.9em;
        height: unset;
        width: unset;
        vertical-align: middle;
    }
    .alert-text {
        padding-left: 5px;
    }
}

.warning-alert {
    background-color: $warning-secondary-color !important;
    border-color: $warning-border-color;
    align-items: center;
    color: $warning-color;
}

.warning-alert-icon {
    color: $warning-primary-color;
}

.success-alert-icon {
    color: $verified-primary-color;
}

.success-alert {
    background-color: $success-secondary-color !important;
    border-color: $success-alert-border-color;
    color: $verified-secondary-color;
}

.ng-dropdown-panel {
    margin-top: 40px;
}

.ng-dropdown-panel.margin-left {
    margin-left: 9px;
}

.ng-dropdown-panel.ng-select-multiple {
    margin-top: 40px;
}

.approved-for-list .ng-dropdown-panel.ng-select-multiple {
    margin-top: 40px;
}

.ng-select.ng-select-disabled
    .ng-select-container
    .ng-value-container
    .ng-value {
    color: inherit !important;
    opacity: 0.5 !important;
}

.mat-tab-label-content {
    color: var(--primary-text-color) !important;
    height: 100%;
    width: 100%;
}

.ng-dropdown-panel.ng-select-bottom {
    padding-bottom: 0px;
}

.ng-dropdown-panel.ng-select-top {
    padding-bottom: 0px;
}

.mat-radio-label-content {
    white-space: initial;
}

.search-input .mat-form-field-wrapper {
    padding-bottom: 0px !important;
}

.search-input .mat-form-field-wrapper .mat-form-field-underline {
    bottom: 0px !important;
}

.ng-select.country-list
    .ng-select-container
    .ng-value-container
    .ng-input
    > input {
    height: 100%;
}

.search-input .mat-form-field-label-wrapper .mat-form-field-label span {
    vertical-align: sub;
}

.ng-dropdown-panel.search-field {
    margin-top: 20px;
}

.gm-style .gm-style-iw-c {
    min-width: 333px !important;
    max-width: unset !important;
    background-color: var(--primary-bg-color) !important;
    .gm-ui-hover-effect > span {
        background: var(--primary-text-color);
    }
    .gm-ui-hover-effect {
        top: 5px !important;
        right: 1px !important;
        width: fit-content !important;
        height: fit-content !important;

        img {
            width: 20px !important;
            height: 20px !important;
            margin: 0 !important;
        }
    }
}

.gm-style .gm-style-iw-t::after {
    background: var(--primary-bg-color) !important;
}

.dark-mode .gm-style .gm-style-iw-c .gm-ui-hover-effect img {
    filter: invert(1);
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
    margin-top: 0px !important;
    padding-top: 0.5416666667em !important;
    padding-bottom: 0.5px !important;
    font-weight: 600 !important;
}

.drop-down-error {
    font-family: inherit !important;
    font-size: 12px !important;
    margin-top: -12px;
    line-height: 1;
}

.drop-down-error-light-mode {
    font-weight: 600 !important;
}

.drop-down-error-dark-mode {
    font-weight: 400 !important;
}

.loading-apps-spinner svg {
    height: 1.2em !important;
    width: 1.2em !important;
}

.custom-input-error {
    font-family: inherit !important;
    font-size: 12px !important;
    margin-top: -12px;
    line-height: 1;
}

.custom-input-error-light-mode {
    font-weight: 600 !important;
}

.custom-input-error-dark-mode {
    font-weight: 400 !important;
}

.ng-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: normal !important;
    padding-bottom: 8px;
    padding-right: 0px;
    line-height: 1.5;
    min-height: unset;
    padding-top: 8px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    white-space: normal;
}

.grouped-option span span {
    line-height: 2;
}

.button-row {
    cursor: pointer;
}

.ng-select.ng-select-multiple.search-multi
    .ng-select-container.ng-has-value
    .ng-value-container {
    padding-top: 0.1875em !important;
}

.search-multi.approved-for-list .ng-dropdown-panel.ng-select-multiple {
    margin-top: 40px !important;
}

.disabled-button .mat-button-wrapper {
    color: var(--disabled-button-text-color) !important;
}

.snack-bar {
    background-color: var(--secondary-dark-primary-bg-color) !important;
    color: var(--primary-text-color) !important;
}

.mat-simple-snackbar-action {
    color: $theme-primary-color !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $theme-primary-color !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $theme-primary-color !important;
}

.mat-slide-toggle-content {
    font-size: 14px;
    padding-right: 1em;
    color: var(--primary-text-color);
}

.flight-number-input
    .mat-form-field-wrapper
    .mat-form-field-flex
    .mat-form-field-infix {
    padding: 0.8375em 0 !important;
    display: flex;
}

.ant-upload {
    height: 100% !important;
    width: 100% !important;
}

button:focus {
    outline: unset !important;
}

.ql-editor strong {
    font-weight: bold;
}

.ql-tooltip {
    left: 0 !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
    background-color: $theme-primary-color !important;
}

.date-picker.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    padding: 0 !important;
}

.date-picker.mat-form-field-appearance-fill
    .mat-form-field-subscript-wrapper
    .mat-error {
    font-weight: 600 !important;
}

.health-credentials-modal {
    position: absolute !important;
    min-height: auto !important;
}

.mat-horizontal-stepper-header {
    margin: 0 24px !important;
    padding: 0px !important;
    height: 40px !important;
}

.mat-step-header[aria-selected='true'] {
    border-bottom: 2px solid $theme-primary-color !important;
}

.mat-horizontal-stepper-header-container {
    padding: 24px 0 15px 0 !important;
}

.phone-select .ng-select-container {
    margin-top: 0.1em !important;
}

.overlay-form {
    background-color: transparent;
    position: absolute;
    display: none;
    height: 100%;
    width: 100%;
    z-index: 2;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}

.mat-tab-label[aria-labelledby='hidden'] {
    display: none;
}

#originCountry ng-select .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 195px !important;
}

.align-left .mat-form-field-flex {
    padding: 0 !important;
}

.custom-date-picker .mat-form-field-infix {
    padding: 0.43em 0 !important;
}

.custom-ng-select .ng-select-container {
    min-height: 45px;

    .ng-value-container {
        padding: 0.25em 0 !important;
    }
}

.flight-ng-select .ng-select-container {
    min-height: 45px;

    .ng-value-container {
        padding: 0.17em 0 !important;
        app-flight-info-option
            app-flight-identity
            .title-container
            .title-text {
            font-size: 1em !important;
        }
    }
}

app-flight-info-option {
    app-flight-identity {
        .title-text {
            .title-text {
                color: $primary-text-color !important;
            }
        }
    }
}

.mat-datepicker-popup {
    overflow: hidden;
    margin-top: 20px;
}

.range-picker .mat-form-field-wrapper .mat-form-field-flex {
    padding: 0em !important;
}

.mat-date-range-input-separator {
    color: $white-color !important;
}

.mat-card {
    background: var(--secondary-bg-color) !important;
}

.icon-filter {
    -webkit-filter: var(--icon-primary-filter) !important; /* Safari/Chrome */
    filter: var(--icon-primary-filter) !important;
}

.history-icon-filter {
    -webkit-filter: var(
        --white-dark-icon-filter
    ) !important; /* Safari/Chrome */
    filter: var(--white-dark-icon-filter) !important;
}
.false-icon {
    filter: $validate-warning-filter;
    width: 24px;
}

.secondary-header {
    background: var(--secondary-dark-primary-bg-color);
    app-card-header {
        .header-main-container {
            background: var(--secondary-dark-primary-bg-color);
        }
    }
}

.md-drppicker td.active,
.md-drppicker td.active:hover {
    background-color: $theme-primary-color !important;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: $theme-primary-color !important;
}

.dropdown-menu {
    width: 100% !important;
    .dropdown-item {
        min-height: 48px !important;
        padding: 0.25rem 1.3rem !important;
    }
}

.config-checkbox.mat-checkbox-checked
    .mat-checkbox-layout
    .mat-checkbox-inner-container
    .mat-checkbox-background {
    background-color: $theme-primary-color !important;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: $preview-bg-color !important;
    color: $tertiary-text-color !important;
}

.config-checkbox label {
    margin-bottom: 0em !important;
}

.mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.save-config-spinner svg circle {
    margin: 0 !important;
    stroke: $inverted-text-color !important;
}

.partial-overlay {
    height: calc(100% - 85px);
}

.flagged-row {
    color: $warning-primary-color !important;
    font-weight: bolder !important;
}

.normal-rows {
    color: var(--primary-text-color) !important;
    font-weight: bolder !important;
}

.spinner {
    margin: 0 !important;
}

.edit-info-tabs div mat-tab-body .mat-tab-body-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.edit-info-tabs .mat-tab-body-wrapper {
    margin-top: 1.5em;
}

.edit-info-tabs
    .mat-tab-header
    .mat-tab-label-container
    .mat-tab-list
    .mat-tab-labels {
    justify-content: space-between;
}

.disabled .mat-datepicker-toggle .mat-icon-button .mat-button-wrapper svg {
    color: $primary-text-color !important;
}
.notice-margin {
    margin: 0px !important;
}
.notice-content {
    display: flex;
    align-items: center;
}
.read-only-container {
    display: flex;
    gap: 0.4em;
    margin-left: 1em;
    margin-right: 1em;
    background-color: $read-only-container-color;
    padding: 0.5em;
    border-radius: 4px;
    .icon-style {
        width: 2em;
        height: 2em;
        filter: var(--note-icon-color);
    }
    .read-mode-message {
        color: $black-color;
        font-size: 0.9em;
    }
}
.notification-center {
    .content {
        .title-text {
            .chip {
                .pill {
                    .pill-info {
                        .pill {
                            color: $white-color !important;
                            background-color: $theme-primary-color;
                            height: fit-content !important;
                            width: fit-content !important;
                            font-size: 12px;
                            padding: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

.box-shadow {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.cdk-overlay-container {
    .cdk-overlay-connected-position-bounding-box {
        z-index: 1001;
    }
    .cdk-global-overlay-wrapper {
        z-index: 1000;
    }
}
.backdrop-background + .cdk-global-overlay-wrapper {
    z-index: 1003 !important;
}

app-interval .mat-form-field-infix {
    width: unset !important;
}

.title-prefix .mat-chip-list .mat-chip-list-wrapper {
    margin: 0 !important;
}
.ocr-json-viewer .segment-type-object > .segment-main,
.ngx-json-viewer .segment-type-array > .segment-main {
    white-space: normal !important;
    max-height: 3em;
    overflow: hidden;
}

@media screen and (max-width: 1024px) {
    .previous-application-panel {
        transform: none !important;
        margin: 0 auto !important;
        margin-bottom: 1em !important;
        max-width: 100vw !important;
    }
}

.ng-placeholder,
.placeholder-container,
.mat-form-field-label {
    color: var(--placeholder-color) !important;
}

.mat-checkbox-frame {
    border-color: var(--checkbox-border-color) !important;
}

.label-info-container .label-title {
    color: var(--primary-text-color) !important;
}

.segment-key {
    color: var(--segment-key-color) !important;
}

.cancel-button {
    color: var(--primary-text-color) !important;
}

app-search-v2 {
    mat-card-actions .save-buttons .disabled {
        color: var(--disabled-button-text-color) !important;
    }
}

.switch-apps-paginator
    .mat-paginator-outer-container
    .mat-paginator-container
    .mat-paginator-range-actions
    .mat-button-disabled {
    color: $dimgrey !important;
}
.mat-snack-bar-container {
    background-color: var(--primary-bg-color) !important;
    .mat-simple-snackbar {
        color: var(--primary-text-color) !important;
    }
}

.decision-checkbox {
    color: $tertiary-text-color !important;
    font-size: 0.8em !important;
}
.mat-tab-header-pagination-after .mat-tab-header-pagination-chevron,
.mat-tab-header-pagination-before .mat-tab-header-pagination-chevron {
    border-color: var(--primary-text-color);
}

.disabled {
    opacity: 0.5;
}

.details-encounter app-encounter-map-card mat-card {
    margin: 0 !important;
    mat-card-content {
        padding: 0 24px !important;
        .map-container {
            height: 225px;
        }
    }
}

.dismiss-btn {
    color: var(--primary-text-color) !important;
}
.config-row app-pill .pill-info .pill {
    box-shadow: none !important;
}
.login-ng {
    .ng-select {
        padding-bottom: 0px;
        line-height: 1.125;
        font-weight: 400;
        font-size: 1rem;
        width: 100%;
    }
    .search-dropdown {
        width: 100% !important;
    }

    .round-flag-icon {
        background-repeat: no-repeat;
        background-position: center;
        height: 1em !important;
        width: 1em !important;
        background-size: cover;
        border-radius: 50%;
        line-height: 1;
    }
    .ng-option {
        padding: 0 !important;
        padding-left: 10px !important;
    }
    .text {
        font-weight: 500;
        font-size: 13px;
        color: inherit;
    }
}

ngx-mat-timepicker
    mat-form-field
    .mat-form-field-wrapper
    .mat-form-field-flex
    .mat-form-field-infix {
    color: $black-color;
}

.disabled-checkbox {
    .mat-checkbox-layout {
        .mat-checkbox-inner-container {
            opacity: 0.4;
        }
        .mat-checkbox-label {
            color: var(--primary-text-color);
            opacity: 0.5;
        }
    }
}

.mat-checkbox-layout {
    .mat-checkbox-label {
        white-space: normal;
    }
}

.state-loader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    margin-top: 35px;
    .error-icon {
        width: 80px;
        height: 80px;
        filter: var(--icon-primary-filter);
    }
    .error-message {
        text-align: center;
        width: 100%;
        padding: 15px;
        font-size: 1.125em;
        color: var(--primary-text-color);
    }
}
.red-snackbar {
    background: $red-color !important;
    max-width: 600px !important;
    .mat-simple-snackbar {
        color: $bodycolor !important;
    }
    .mat-button-wrapper {
        color: $bodycolor !important;
    }
}
.success-snackbar {
    background: $success-primary-color !important;
    max-width: 600px !important;
    .mat-simple-snackbar {
        color: $bodycolor !important;
    }
    .mat-button-wrapper {
        color: $bodycolor !important;
    }
}
.refresh-failure-snackbar {
    .mat-button-wrapper {
        color: $red-color !important;
    }
}
.encounter-full-view {
    .card-header {
        .header-main-container {
            padding: 0px !important;
            .header-title
                .flight-info-container
                .flight-info-row
                .flight-info
                h5 {
                margin-top: 0.5em !important;
            }
        }
    }
}

.group-cards {
    .card-dims {
        min-width: unset !important;
    }
    .mat-card {
        margin: 0px !important;
    }
}
.next-app-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    .success-spinner {
        width: 100px !important;
        height: 80px !important;
        svg {
            width: 55px !important;
            height: 55px !important;
            margin-top: 10px;
        }
    }
}

.dates
    ng-dropdown-panel
    .ng-dropdown-panel-items
    .scrollable-content
    .ng-option {
    white-space: normal;
}

.row-form > * {
    width: calc(50% - 1em) !important;
}

.three-row-form {
    width: calc(32% - 1em) !important;
}

.customized-scroll {
    overflow-x: hidden;
}

.customized-scroll-viewport::-webkit-scrollbar {
    width: 0.3em;
    height: 1em;
}

.customized-scroll-viewport::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 100vw;
}

.customized-scroll-viewport:hover {
    cursor: pointer;
}

@media screen and (max-width: 456px) {
    .group-section {
        app-applicant-card {
            .card-dims {
                min-width: unset !important;
            }
        }
    }
}
@media screen and (max-width: 400px) {
    .full-width-panel-class {
        width: 95vw !important;
        max-width: 95vw !important;
    }
}

@media screen and (max-width: 867px) {
    .group-submissions-row > * {
        width: 100% !important;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.fade-in-modal {
    animation: fadeIn 1s;
}

.narrow-paginator
    .mat-paginator-outer-container
    .mat-paginator-container
    .mat-paginator-range-actions
    .mat-paginator-range-label {
    font-weight: 600 !important;
    min-width: unset !important;
    margin: 0 0 0 20px !important;
}

.mat-form-field-suffix .arrows-wrap .arrow svg {
    filter: $theme-primary-filter;
}

ngx-mat-timepicker-field .ngx-mat-timepicker .separator-colon {
    color: var(--primary-text-color);
}
ngx-mat-timepicker-time-control
    .ngx-mat-timepicker-control
    .mat-form-field-infix
    .mat-form-field-label-wrapper {
    text-align: center;
}

.marker-label-class {
    width: 20px !important;
    height: 19px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #ffffff !important;
    border-radius: 50% !important;
}

button.mat-calendar-body-cell {
    position: absolute;
    height: 100%;
}

td.mat-calendar-body-cell {
    position: relative;
    height: 0;
}
