$dark-mode-bg: #424242;
$dark-grey: darkgrey;
$dark-main-bg: #303030;
$bodycolor: #eef5f9;
$disabled-text: #a5a5a5;
$light-grey: lightgrey;
$black-color: #000000;
$dimgrey: dimgrey;
$grey: grey;
$subtitle-color: #a1a1a1;
$chip-disabled-text-color: #585151;
$read-only-container-color: #eae6ff;
////////////////

//Theme colors
$theme-primary-color: #05c29f; //theme color //#05c29f cayan
$theme-tertiary-color: #88ffe9;
$header-bg-color: #607d8b; //header background color
$chips-bg-color: #a9a9a9;
$processing-primary-color: #d18a1c;
$approved-primary-color: #71b644;
$question-primary-color: #ffc507;
$examine-primary-color: #dc7201;
$quarantine-primary-color: #e42220;
$denied-primary-color: #3f51b5;
$agent-exit-bg-color: #378b00;
$agent-entry-bg-color: #152997;
$processing-secondary-color: #eaeaea;
$white-color: #ffffff;
$theme-secondary-color: #05c29f8c;
$trav-grey-bg-color: #eee;
$trav-grey-color: #212529;
$red-color: red;
$yellow-fever-country: #cccc00;
$red-fever-country: #ff0000;
$yellow-green-outerstroke-color: #78c000;
$white-green-innerstroke-color: #c7e596;

//UI colors
$success-primary-color: #71b644;
$success-secondary-color: #d4edda;
$danger-primary-color: #e42220;
$danger-secondary-color: #f2e5e4;
$danger-tertiary-color: #f2e5e4;
$warning-secondary-color: #fff3cd;
$warning-primary-color: #ff991f;
$warning-tertiary-color: #fbe2c1;
$approve-with-action-bg-color: #30c6da;
$encounter-bg-color: #0093a7;
$broken-image-color: #ac9797;
$dimmed-primary-color: #99abb4;
$icon-bg-color: #dbdbdb;
$preview-bg-color: #808080;
$location-pin-color: #c70303;
$queue-uploader-bg-color: #d9d9d9;
$tool-panel-bg-color: #f8f8f8;
$uploader-bg-color: #4c4a4a;
$validate-success-bg-color: #d9e7ff;
$validate-invalid-bg-color: #edd4d4;
$validate-warning-bg-color: #fcf1c9;
$mode-btn-bg-color: #d3d3d3;
$dark-secondary-bg-color: #303030;
$dark-primary-bg-color: #424242;
$primary-bg-color: white;
$skeleton-bg-color: #eff1f6;
$secondary-bg-color: #f6f6f6;
$tertiary-bg-color: #ececec;
$inverted-bg-color: black;
$inactive-bg-color: #e0e0e0;
$backdrop-bg-color: #000000a8;
$faded-bg-color: #f5f5f5;
$verified-tertiary-color: #d4edda;
$light-orange-bg: #f8cb8f;
$trav-green-bg: #71b6448c;
$light-segment-key-color: #4e187c;
$dark-segment-key-color: white;
$disabled-button-light-text: rgba(0, 0, 0, 0.26);
$disabled-light-confirmation-button: #b6b6b6;
$disabled-button-dark-text: #b3b3b3;
$light-disabled-icon: #bdbdbd;
$dark-disabled-icon: #8b8e8f;
$code-dark-bg-color: #353232;
$faded-light-color: #f3f3f3;
$orange-greyish-color: #ffbf00;
//Text Colors
$primary-text-color: black;
$secondary-text-color: #99abb4;
$tertiary-text-color: #808080;
$light-text-color: #8e8e8e;
$inverted-text-color: white;
$muted-text-color: #9c9c9c;
$dark-text-color: #000000de;
$disabled-text-color: #ffffff66;
$debug-text-color: #d3d3d3;
$pre-check-text-color: #8f8c8c;
$link-text-color: #007bff;
$link-secondary-text-color: #1a0dab;
$visited-link-text-color: #609;
$processing-badge-text-color: #7c7c7c;
$validate-success-text-color: #002857;
$validate-invalid-text-color: #5d0808;
$validate-warning-text-color: #575300;
$form-title-text-color: #969696;
$purple-text-color: #ed1dd3;
$verified-primary-color: #00875a;
$verified-secondary-color: #155724;
$light-placeholder-color: rgba(0, 0, 0, 0.54);
$opacity-dark: #0000008a;
$error-text-color: #e91e63;
$updated-row-text-color: rgb(204, 204, 0);
$updated-badge-bg-color: #ffff00cf;
$warning-color: #856404;
$grey-color: #918f8f;
$light-grey-text-color: #838383;
$opacity-black: rgba(0, 0, 0, 0.87);

//Border Colors
$light-border-color: #babfc7;
$dark-tool-panel-border: #68686e;
$chips-border-color: #aaa;
$uploader-border-color: #d9d9d9;
$separator-border-color: #b9b7b7;
$child-separator-border-color: #d3d3d33e;
$email-separator-border-color: #82767691;
$dark-panel-border-color: #0000001f;
$stream-sidebar-border-color: #808080;
$checkbox-light-border: rgba(255, 255, 255, 0.69);
$light-radio-button-border: #9e9e9e;
$blue-border-color: #4d90fe;
$warning-border-color: #ffeeba;
$success-alert-border-color: #c3e6cb;
$grey-box-shadow: #888888;
$checkbox-dark-border: #88886d;

//Filters
$white-svg-filter: invert(1);
$black-svg-filter: invert(0);
$theme-primary-filter: invert(56%) sepia(73%) saturate(1522%) hue-rotate(126deg)
    brightness(93%) contrast(96%);

$success-primary-filter: invert(64%) sepia(16%) saturate(1619%)
    hue-rotate(53deg) brightness(100%) contrast(76%);

$light-primary-filter: invert(0%) sepia(0%) saturate(13%) hue-rotate(235deg)
    brightness(105%) contrast(105%);

$dark-primary-filter: brightness(100) invert(1);
$light-svg: invert(1) brightness(2);

$disabled-primary-filter: invert(18%) sepia(0%) saturate(6%) hue-rotate(5deg)
    brightness(240%) contrast(86%);

$disabled-secondary-filter: invert(18%) sepia(0) saturate(6%) hue-rotate(5deg)
    brightness(104%) contrast(86%);

$validate-invalid-filter: invert(14%) sepia(96%) saturate(7153%)
    hue-rotate(360deg) brightness(98%) contrast(106%);

$validate-warning-filter: invert(70%) sepia(34%) saturate(5862%)
    hue-rotate(4deg) brightness(107%) contrast(105%);
$no-brightness: brightness(0);
$brightness: brightness(100);
$green-color-filter: invert(64%) sepia(6%) saturate(4519%) hue-rotate(102deg)
    brightness(93%) contrast(80%);
$danger-primary-color-filter: invert(12%) sepia(92%) saturate(5800%)
    hue-rotate(356deg) brightness(107%) contrast(70%);
$purple-icon-color-filter: invert(30%) sepia(41%) saturate(1728%)
    hue-rotate(221deg) brightness(87%) contrast(95%);
$trav-green-filter: invert(70%) sepia(74%) saturate(3719%) hue-rotate(125deg)
    brightness(92%) contrast(96%);
$light-shimmer-color: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    #ebebebb8 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
);
$dark-shimmer-color: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
);
$grey-icon-filter-color: invert(50%) sepia(0) saturate(6%) hue-rotate(5deg)
    brightness(104%) contrast(86%);
$theme-filter-color: invert(46%) sepia(89%) saturate(1264%) hue-rotate(133deg)
    brightness(101%) contrast(96%);
$light-green-filter-color: invert(92%) sepia(5%) saturate(5722%)
    hue-rotate(45deg) brightness(124%) contrast(80%);
$light-red-filter-color: invert(66%) sepia(83%) saturate(3624%)
    hue-rotate(318deg) brightness(93%) contrast(83%);
$dark-red-filter-color: invert(18%) sepia(29%) saturate(5180%)
    hue-rotate(338deg) brightness(104%) contrast(130%);
$danger-red-filter-color: invert(10%) sepia(90%) saturate(6373%)
    hue-rotate(0deg) brightness(120%) contrast(115%);
:root {
    //colors that do not change in light and dark mode (used in ts files)
    --white-color: #{$white-color};
    --black-color: #{$black-color};
    --quarantine-color: #{$quarantine-primary-color};
    --approve-color: #{$approved-primary-color};
    --examine-color: #{$examine-primary-color};
    --question-color: #{$question-primary-color};
    --agent-exit-color: #{$agent-exit-bg-color};
    --agent-entry-color: #{$agent-entry-bg-color};
    --denied-color: #{$denied-primary-color};
    --approve-with-action-color: #{$approve-with-action-bg-color};
    --encounter-color: #{$encounter-bg-color};
    --dimmed-color: #{$dimmed-primary-color};
    --theme-primary-color: #{$theme-primary-color};
    --red-color: #{$red-color};
    --yellow-fever-country: #{$yellow-fever-country};
    --red-fever-country: #{$red-fever-country};
    --processing-badge-text-color: #{$processing-badge-text-color};
    --yellow-green-outerstroke-color: #{$yellow-green-outerstroke-color};
    --white-green-innerstroke-color: #{$white-green-innerstroke-color};
    --header-bg-color: #{$header-bg-color};
    --opacity-black: #{$opacity-black};
    --warning-primary-color: #{$warning-primary-color};
    --processing-primary-color: #{$processing-primary-color};
    --processing-secondary-color: #{$processing-secondary-color};
    //background variable colors
    --white-black-bg-color: #{$inverted-bg-color};
    --main-bg-color: #{$bodycolor};
    --secondary-bg-color: #{$secondary-bg-color}; //#f6f6f6
    --primary-bg-color: #{$primary-bg-color}; //white
    --skeleton-bg-color: #{$skeleton-bg-color};
    --history-bg-color: #{$tertiary-bg-color};
    --primary-dark-secondary-bg-color: #{$primary-bg-color}; //white
    --secondary-dark-primary-bg-color: #{$secondary-bg-color}; //#f6f6f6
    --tool-panel-bg-color: #{$tool-panel-bg-color};
    --tertiary-dark-secondary-bg-color: #{$tertiary-bg-color}; //#ececec
    --faded-dark-secondary-bg-color: #{$faded-bg-color}; //#f5f5f5
    --faded-dark-primary-bg-color: #{$faded-bg-color}; //#f5f5f5
    --address-warning-panel-bg-color: #{$warning-secondary-color};
    --chip-bg-color: #{$inactive-bg-color};
    --dark-secondary-primary-bg-color: #{$dark-secondary-bg-color};
    --code-section-bg-color: #{$faded-bg-color};
    --queue-file-uploader-bg-color: #{$queue-uploader-bg-color};
    --secondary-primary-bg-color: #{$secondary-bg-color};
    --theme-secondary-bg-color: #{$theme-secondary-color};

    //text color variables
    --white-black-text-color: #{$inverted-text-color};
    --primary-text-color: #{$primary-text-color}; //black
    --light-secondary-text-color: #{$light-text-color}; //#8e8e8e
    --light-primary-text-color: #{$light-text-color}; //#8e8e8e
    --dark-inverted-text-color: #{$dark-text-color}; //000000de
    --tertiary-inverted-text-color: #{$tertiary-text-color}; //#808080
    --segment-key-color: #{$light-segment-key-color};
    --ng-select-input-text-color: #{$backdrop-bg-color}; //000000a8
    --opacity-dark-text-color: #{$opacity-dark}; //0000008a
    --opacity-black-white-color: $opacity-black;

    --disabled-button-text-color: #{$disabled-button-light-text};
    --disabled-icon-color: #{$light-disabled-icon};
    --radio-button-border: #{$light-radio-button-border};
    --disabled-confirmation-button: #{$disabled-light-confirmation-button};

    --icon-primary-filter: #{$light-primary-filter};
    --brigthness-svg: #{$no-brightness};
    --dark-primary-filter: #{$dark-primary-filter};
    --icon-green-filter: #{$trav-green-filter};

    --light-border-color: #{$light-border-color};
    --tool-panel-border: #{$light-border-color};

    --checkbox-border-color: #{$inverted-bg-color}; //black
    --placeholder-color: #{$light-placeholder-color};

    --danger-primary-color: #{$danger-primary-color};
    --black-white-color-filter: #{$black-svg-filter};
    --yellow-color-filter: #{$validate-warning-filter};
    --white-svg-color: #{$white-svg-filter};
    --note-icon-color: #{$purple-icon-color-filter};
    --shimmer-color: #{$light-shimmer-color};
    --danger-icon-filter: #{$danger-primary-color-filter};
    --dark-red-icon-filter: #{$dark-red-filter-color};
    --danger-red-icon-filter: #{$danger-red-filter-color};
    --light-red-icon-filter: #{$light-red-filter-color};
    --green-color-icon-filter: #{$green-color-filter};
    --theme-color-icon-filter: #{$theme-filter-color};
    --light-green-color-icon-filter: #{$light-green-filter-color};
    --grey-color-icon-filter: #{$grey-icon-filter-color};
    --white-dark-icon-filter: #{$white-svg-filter};
}

.dark-mode {
    //background variable colors
    --white-black-bg-color: #{$white-color};
    --main-bg-color: #{$dark-main-bg};
    --secondary-bg-color: #{$dark-secondary-bg-color}; //#303030
    --primary-bg-color: #{$dark-primary-bg-color}; //#424242
    --skeleton-bg-color: #{$dark-primary-bg-color};
    --history-bg-color: #{$dark-primary-bg-color};
    --primary-dark-secondary-bg-color: #{$dark-secondary-bg-color}; //#303030
    --secondary-dark-primary-bg-color: #{$dark-primary-bg-color}; //#424242
    --tool-panel-bg-color: #{$dark-mode-bg};
    --tertiary-dark-secondary-bg-color: #{$dark-secondary-bg-color};
    --faded-dark-secondary-bg-color: #{$dark-secondary-bg-color};
    --faded-dark-primary-bg-color: #{$dark-primary-bg-color};
    --address-warning-panel-bg-color: #{$dark-primary-bg-color};
    --chip-bg-color: #{$white-color};
    --dark-secondary-primary-bg-color: #{$white-color};
    --code-section-bg-color: #{$code-dark-bg-color};
    --queue-file-uploader-bg-color: #{$dark-secondary-bg-color};
    --secondary-primary-bg-color: #{$white-color};
    --theme-secondary-bg-color: #{$theme-secondary-color};

    //text color variables
    --white-black-text-color: #{$primary-text-color};
    --primary-text-color: #{$inverted-text-color}; //white
    --light-secondary-text-color: #{$secondary-text-color}; //#99abb4
    --light-primary-text-color: #{$inverted-text-color}; //white
    --dark-inverted-text-color: #{$inverted-text-color}; //white
    --tertiary-inverted-text-color: #{$inverted-text-color}; //white
    --segment-key-color: #{$dark-segment-key-color};
    --disabled-button-text-color: #{$disabled-button-dark-text};
    --disabled-icon-color: #{$dark-disabled-icon};
    --radio-button-border: #{$inverted-text-color};
    --ng-select-input-text-color: #{$inverted-text-color};
    --opacity-dark-text-color: #{$inverted-text-color};
    --opacity-black-white-color: #{$inverted-text-color};

    --icon-primary-filter: #{$dark-primary-filter};
    --icon-green-filter: #{$trav-green-filter};
    --brigthness-svg: #{$brightness};
    --dark-primary-filter: #{$light-svg};
    --disabled-confirmation-button: #{$disabled-button-dark-text};

    --light-border-color: #{$light-border-color};
    --tool-panel-border: #{$dark-tool-panel-border};

    --checkbox-border-color: #{$inverted-text-color};
    --placeholder-color: #{$inverted-text-color};

    --danger-primary-color: #{$danger-primary-color};
    --black-white-color-filter: #{$white-svg-filter};
    --yellow-color-filter: #{$validate-warning-filter};
    --white-svg-color: #{$white-svg-filter};
    --note-icon-color: #{$purple-icon-color-filter};
    --shimmer-color: #{$dark-shimmer-color};
    --danger-icon-filter: #{$danger-primary-color-filter};
    --dark-red-icon-filter: #{$dark-red-filter-color};
    --danger-red-icon-filter: #{$danger-red-filter-color};
    --light-red-icon-filter: #{$light-red-filter-color};
    --green-color-icon-filter: #{$green-color-filter};
    --theme-color-icon-filter: #{$theme-filter-color};
    --light-green-color-icon-filter: #{$light-green-filter-color};
    --grey-color-icon-filter: #{$grey-icon-filter-color};
    --white-dark-icon-filter: #{$black-svg-filter};
}
