// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$material-primary: mat.define-palette(mat.$indigo-palette);
$material-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$material-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$material-theme: mat.define-light-theme(
    $material-primary,
    $material-accent,
    $material-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($material-theme);

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@import 'variable';

// -@import 'icons/themify-icons/themify-icons.css';
@import 'icons/font-awesome/css/font-awesome.css';
@import 'spinner';
@import 'app';
@import 'header';
@import 'widgets';
@import 'pages';
@import 'sidebar';
@import 'dark-sidebar';
@import 'responsive';
@import 'rtl';

// Red Theme

.danger {
    @import 'colors/red';
    @include mat.all-component-themes($red-app-theme);
}

// Blue Theme

.blue {
    @import 'colors/blue';
    @include mat.all-component-themes($blue-app-theme);
}

// Green Theme

.green {
    @import 'colors/green';
    @include mat.all-component-themes($green-app-theme);
}

// Dark Theme

.dark {
    @import 'colors/dark';
    @include mat.all-component-themes($dark-theme);
}

$custom-typography: mat.define-typography-config(
    $font-family: 'Poppins',
);
@include mat.core($custom-typography);
